export default defineNuxtRouteMiddleware((to, from) => {

    /// If fullPath is /app/upgrade, redirect to old site

    if(to.fullPath === '/app/private-sale-pass') {
        return navigateTo('/app', {
            external: true
          })
    }

    if (to.fullPath === '/app/upgrade') {
        return navigateTo('https://v2.oxbull.tech/app/upgrade', {
            external: true
          })
    }

    /// If fullPath is /app/oxs-migrate, redirect to old site

    if (to.fullPath === '/app/oxs-migrate') {
        return navigateTo('https://v2.oxbull.tech/app/oxs-migrate', {
            external: true
          })
    }

})
