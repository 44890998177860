import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@2.79.2_sass@1.83.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/opt/buildhome/repo/middleware/redirect.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_rollup@2.79.2_sass@1.83.0_typescript@5.7.2_vite@5.4.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  account: () => import("/opt/buildhome/repo/middleware/account.ts"),
  app: () => import("/opt/buildhome/repo/middleware/app.ts"),
  claim: () => import("/opt/buildhome/repo/middleware/claim.ts"),
  landing: () => import("/opt/buildhome/repo/middleware/landing.js")
}